<template>
  <MasterDetail
  ref="masterDetail"
  :actionBarButtons="actionBarButtons"
  :canEdit="false"
  :canDelete="false"
  :cols="cols"
    :contextOptions="options"
    :resourceUrl="resourceUrl"
    descriptionProperty="titulo"
    :hasNewButton="false"
    :hasExportCSV="false"
    :hasYearFilter="hasYearFilter"
    :opts="opts"
    :selection.sync="selection"
    :selectionCol="userCanApprove || userCanDisapprove"
    :serverPagination="true"
    @mounted="doLoad = $event.doLoad"
    >
    <FormModal
    :cols="statusCols"
    :opened.sync="statusModalOpened"
    :opts="opts"
    :errorMessage="errorMessage"
      title="Alterar Status"
      ref="modalStatusRef"
      :value.sync="statusModalData"
      @save="saveStatus"
      ></FormModal>

    <GModal
    ref="statusDialogWarning"
    appendTitleIcon="mdi-alert-box-outline"
    title="Alteração de Status"
    >
      <p v-if="statusDialogWarning.pending">Foram removidos projetos <span v-if="statusDialogWarning.approve">aprovados </span><span v-if="statusDialogWarning.approve && statusDialogWarning.reprove">e/ou </span><span v-if="statusDialogWarning.reprove">reprovados </span> dos itens selecionados.</p>
      <p v-if="statusDialogWarning.approve">Para alterar o status de um projeto <b style="color: #25935F">Aprovado</b>, você deve ir na tela de enquadrados.</p>
      <p v-if="statusDialogWarning.reprove">A alteração de status de projetos <b style="color: #EF4848">Reprovados</b> não é permitido.</p>

      <template v-slot:buttons="{ close }">
        <v-spacer></v-spacer>
        <v-btn
        class="px-5"
        color="primary"
        depressed
        @click="close()"
        >
          Fechar
        </v-btn>
        <v-spacer></v-spacer>
      </template>
    </GModal>

    <FormModal
    :cols="modalFields"
    class="justificativa"
    id="justificativa"
    maxWidth="60%"
      :opened.sync="justificativaModalOpen"
      :opts="opts"
      :title="`Justificativa de enquadramento para o projeto: ${tituloProjetoSelecionado}`"
      :value.sync="justificativaModalData"
      @save="salvarJustificativaEnquadramento"
      >
    </FormModal>

    <FormModal
    :cols="modalFields"
    class="justificativa"
    id="justificativa"
    maxWidth="60%"
      :opened.sync="justificativaSelectionModalOpen"
      :opts="opts"
      title="Justificativa de enquadramento"
      :subtitle="justificativaSubtitle"
      :value.sync="justificativaSelectionModalData"
      @save="salvarJustificativaEnquadramentoEmLote"
      >
    </FormModal>

    <v-dialog v-model="userJustify" max-width="500">
      <v-card class="teros-elevation">
        <v-card-title class="border">
          <div class="d-flex align-center">
            <v-icon class="mr-3">mdi-square-edit-outline</v-icon>
            <span>Justificativa de enquadramento</span>
          </div>
        </v-card-title>
        <v-card-text class="mt-3">
          <div v-if="justificativaModalData.justificativa">
            <span class="pt-2 pl-1">{{ justificativaModalData.justificativa }}</span>
          </div>
          <span class="pt-2 pl-1" v-if="!justificativaModalData.justificativa">Não há justificativa cadastrada para esse projeto.</span>
        </v-card-text>
        <v-card-actions class="border d-flex justify-center">
          <v-btn color="primary"
          dark
          depressed
          class="px-5"
          @click="userJustify = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <GModal ref="confirmJustify" title="Justificativa de enquadramento">
      <p>Deseja preencher uma justificativa de enquadramento para os projetos selecionados?</p>

      <template v-slot:buttons="{ close }">
        <v-spacer />
        <v-btn
          class="px-3"
          color="secondary"
          dark
          depressed
          @click="close(false)"
        >
          Não
        </v-btn>
        <v-btn
          class="px-3"
          color="primary"
          dark
          depressed
          @click="close(true)"
        >
          Sim
        </v-btn>
      </template>
    </GModal>

    <v-dialog v-model="isProjetosJustificadosEmLote" max-width="500">
      <v-card class="teros-elevation">
        <v-card-title>
          <v-icon class="mr-3">mdi-alert-box-outline</v-icon>
          Justificativa de enquadramento
        </v-card-title>
        <v-card-text>
          <div v-if="projetosSelecionadosParaJustificacao.length">
            <div v-if="projetosJustificados.length">
              <p>Alguns dos projetos selecionados já possuem uma justificativa cadastrada.</p>
              <p>
                <b v-if="selection.length === 1">Deseja alterar a justificativa deste projeto?</b>
                <b v-else>Deseja alterar a justificativa de todos os projetos?</b>
              </p>
              <p>Caso opte por <b>NÃO</b>, será adicionado somente as justificativas para os projetos que não possuem
                justificativas prévias</p>
            </div>
            <div v-else>
              <p>Nenhum dos projetos selecionados possuem justificativa cadastrada.</p>
              <p><b>Deseja alterar a justificativa de todos os projetos?</b></p>
            </div>
          </div>
          <div v-else>
            <p>Você optou por preencher a justificativa dos projetos que não possuem justificativa, mas todos os
              projetos selecionados já possuem uma justificativa cadastrada.</p>
            <p>Deseja prosseguir com a
              <b v-if="justificativaSelectionModalData.isApproved">APROVAÇÃO</b>
              <b v-else>REPROVAÇÃO</b>
              dos projetos?
            </p>
          </div>
        </v-card-text>
        <v-card-action class="p-2 d-flex justify-end" v-if="projetosSelecionadosParaJustificacao.length">
          <v-btn color="secondary"
          dark
          depressed
          class="px-5 mr-2"
            @click="isProjetosJustificadosEmLote = false; justificativaSelectionModalOpen = false">
            <v-icon left>mdi-chevron-left</v-icon>
            Cancelar
          </v-btn>
          <v-btn color="primary"
          v-if="projetosJustificados.length"
          dark
          depressed
          class="px-5 mr-2"
          @click="
            removeSelecaoProjetosJustificados();
          justificativaSubtitle = 'Você está preenchendo a justificativa para projetos não justificados'">Não</v-btn>
          <v-btn color="primary"
          dark
          depressed
          class="px-5"
          @click="
            openJustifySelectionModal();
          justificativaSubtitle = `${projetosJustificados.length ? 'Você está preenchendo a justificativa para todos os projetos selecionados, isso sobreescreverá as justificativas já cadastradas' : 'Você está preenchendo a justificativa para todos os projetos selecionados'}`
            ">Sim</v-btn>
        </v-card-action>
        <v-card-action class="p-2 d-flex justify-end" v-else>
          <v-btn
          class="px-5 mr-2"
          color="secondary"
          depressed
            @click="isProjetosJustificadosEmLote = false; justificativaSelectionModalOpen = false">
            <v-icon left>mdi-chevron-left</v-icon> Cancelar
          </v-btn>
          <v-btn
          class="px-5"
          color="primary"
          depressed
          @click="alterarSituacaoProjetos(justificativaSelectionModalData.isApproved);
          isProjetosJustificadosEmLote = false; justificativaSelectionModalOpen = false">
            Sim
          </v-btn>
        </v-card-action>
      </v-card>
    </v-dialog>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div class="table-v-action-button mr-3" v-bind="attrs" v-on="on">
          <v-icon>mdi-file-delimited-outline</v-icon>
          Exportações Assíncronas
        </div>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-title
            class="table-v-action-button"
            @click="exportCsv(false)"
          >
            <v-icon>mdi-file-delimited</v-icon>
            para conferência
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            class="table-v-action-button"
            @click="exportCsv(true)"
          >
            <v-icon>mdi-file-arrow-up-down</v-icon>
            para alteração
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog
      v-model="exportacaoAsyncDialog"
      scrollable
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title style="display: block" class="pb-1">
          <v-icon x-large class="mr-3">mdi-file-delimited-outline</v-icon>
          Exportar CSV
          <v-btn
            @click="exportacaoAsyncDialog = false"
            style="position: absolute; right: 5px; top: 5px"
            icon
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p>Requisição entrou na fila para processamento.</p>
          <p>Para ver o progresso e baixar o relatório, veja a fila.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="px-6 pb-3 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="pr-5"
            dark
            depressed
            @click="exportacaoAsyncDialog = false"
          >
            <v-icon left>mdi-chevron-left</v-icon> Fechar
          </v-btn>
          <v-btn
            color="primary"
            dark
            depressed
            class="px-5 ml-3"
            @click="verFila()"
          >
            Ver fila
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </MasterDetail>
</template>

<script>
import { mapGetters } from "vuex";
import { partition } from "lodash";
import moment from "moment";
import { BeneficiosEnum, BeneficiosLabels } from '@/core/enums/beneficios';
import { EnquadramentoEnum } from '@/core/enums/projetos';
import { AnyAdmin, AnyClient } from '@/core/enums/user-types';

export default {
  components: {
    MasterDetail: () => import("@/components/master-detail.vue"),
    FormModal: () => import("@/components/form-modal.vue"),
    GModal: () => import("@/components/g-modal.vue"),
  },
  computed: {
    ...mapGetters(['clientId', 'user']),
    actionBarButtons: function () {
      const buttons = [
        {
          text: "Adicionar",
          icon: "mdi-plus-box-outline",
          show: this.userHasAccessToAction('create'),
          action: () => {
            const route = this.isClient ? { name: "inclusao-projeto" } : { name: "cadastro-projeto" };
            this.$router.push(route);
          },
        },
        {
          text: "Aprovar",
          icon: "mdi-check-circle-outline",
          show: this.selection.length && this.userCanApprove,
          action: () => {
            this.$refs.confirmJustify.asyncOpen().then((confirm) => {
              if (!confirm) {
                return this.alterarSituacaoProjetos(true);
              }
              this.validaProjetosComJustificativaEmLote(true);
            })
          },
        },
        {
          text: "Reprovar",
          icon: "mdi-close-circle-outline",
          show: this.selection.length && this.userCanDisapprove,
          action: () => {
            this.$refs.confirmJustify.asyncOpen().then((confirm) => {
              if (!confirm) {
                return this.alterarSituacaoProjetos(false);
              }
              this.validaProjetosComJustificativaEmLote(false);
            })
          },
        },
        {
          text: "Alterar Status",
          icon: 'mdi-update',
          show: this.selection.length && (this.userCanApprove || this.userCanDisapprove),
          action: () => {
            this.validaAlteracaoStatusProjeto();
          },
        }
      ];

      return buttons.filter(({ show }) => show);
    },
    hasYearFilter: function () {
      return { max: moment().add(2, 'year').get('year') };
    },
    resourceUrl: function () {
      return `/v1/projetos/${this.clientId}/analise`;
    },
    isClient: function () {
      return this.getClient().isClient;
    },
    options: function () {
      let options = [];
      if(this.userHasAccessToAction('create')) {
        options.push({
          name: "Ver ficha de análise",
          limit: 1,
          cb: (row) => {
            const route = {
              name: "edicao-inclusao-projeto",
              params: { projetoId: row.id },
            };
            this.$router.push(route);
          },
        })
      }

      options.push({
        name: "Alterar Status",
        limit: 1,
        show: (this.userCanApprove || this.userCanDisapprove),
        cb: (row) => {
          this.validaAlteracaoStatusProjeto(row);
        },
      })

      if(this.userHasAccessToAction('create')) {
        options.push({
          name: "Justificativa de enquadramento",
          limit: 1,
          disabledTitle: `Justificativa em lote só é permitida via planilha ou aprovação/reprovação de projetos`,
          disabled: this.selection.length,
          cb: (row) => {
            this.justificativaModalData = row;
            this.tituloProjetoSelecionado = row.titulo;

            if(this.isUser) {
              return this.userJustify = true;
            }
            this.justificativaModalOpen = true;
          },
        })
      }

      return options;
    },
    // resource: function () {
    //   let resource = this.apiResource(this.resourceUrl);
    //   return {
    //     get: function (param) {
    //       return new Promise((resolve, reject) => {
    //         resource
    //           .get(param)
    //           .then((result) => {
    //             this.projetos = result.data;
    //             forEach(result.projetos, (p) => {
    //               if (
    //                 p.status_analise == 0 &&
    //                 moment().subtract(2, "days").isAfter(moment(p.criado_em))
    //               ) {
    //                 p.status_analise = -1;
    //               }

    //               if(p.data_analise){
    //                 var mDataAnalise = moment(p.data_analise);
    //                 var mCriadoEm = moment(p.criado_em);
    //                 var daysDiff = mDataAnalise.diff(mCriadoEm, "days");

    //                 for (var i = 0; i < daysDiff; i++) {
    //                   var mDate = moment(mCriadoEm);
    //                   mDate.add(i, "days");
    //                   if(mDate.day() == 0 || mDate.day() == 6){
    //                     mDataAnalise.subtract(1, "days");
    //                   }
    //                 }

    //                 p.tempo_conclusao = mDataAnalise.diff(mCriadoEm);
    //               }

    //               //tempo_conclusao = data_analise - criado_em

    //               if(!p.consultorId){
    //                 var cliente = result.clientes.find(c => c.id == p.empresaId);
    //                 if(cliente){
    //                   p.consultorId = cliente.consultorProjetosId;
    //                 }
    //               }
    //             });
    //             resolve(result);
    //           })
    //           .catch(reject);
    //       });
    //     },
    //   };
    // },
    // resourceStatus: function () {
    //   return this.apiResource(`/v1/projetos/${this.client.clientId}/status`);
    // },

    userCanApprove: function () {
      return this.userHasAccessToAction('aprovar');
    },
    userCanDisapprove: function () {
      return this.userHasAccessToAction('reprovar');
    },

    userCanEdit: function () {
      return AnyAdmin.includes(this.user.tipo_usuario)
    },
    isUser: function () {
      return AnyClient.includes(this.user.tipo_usuario)
    },
    resourceProjetos: function () {
      return this.apiResource(`/v1/projetos/${this.clientId}/projeto`);
    },
    modalFields: function() {
      return [{
        key: "justificativa",
        name: "Justificativa",
        type: this.$fieldTypes.TEXTAREA,
        editable: !this.isUser,
        rules: [{ rule: "max", params: { size: 2000 } }]
      }]
    },
  },
  data: function () {
    return {
      errorMessage: null,
      statusDialogWarning: {
        approve: false,
        pending: false,
        reprove: false,
      },
      dialogMessageOpened: false,
      cols: [
        { key: "id", name: "ID", hideInTable: true },
        {
          key: "empresa",
          name: "Cliente",
          hideInTable: !this.clientId,
        },
        { key: "id_projeto", name: "Código" },
        {
          key: "titulo",
          name: "Nome do Projeto",
          nowrap: true,
          truncate: true,
          width: '350px',
        },
        {
          key: "agrupamento_titulo",
          name: "Agrupamento",
          nowrap: true,
          tooltip: true,
        },
        {
          key: "enquadramento",
          name: "Enquadramento",
          nowrap: true,
          align: 0,
          type: this.$fieldTypes.HTML,
          rel: { to: "statusEnquadramento", key: "id", name: "nome" },
        },
        {
          key: "trabalho",
          name: "Benefício",
          type: this.$fieldTypes.HTML,
          rel: { to: "trabalhos", key: "id", name: "nome" },
          nowrap: true,
          align: 0,
        },
        {
          key: "horas_timesheets",
          name: "Horas Timesheets",
          type: this.$fieldTypes.TIME,
        },
        {
          key: "status_label",
          name: "Status",
          type: this.$fieldTypes.TEXT,
          width: "175px",
          align: 0,
        },
        {
          key: "ano_base",
          name: "Ano Base",
          align: 0,
        },
        {
          key: "responsavelTxt",
          name: "Responsável",
        },
        {
          key: "criado_em",
          name: "Cadastro Há",
          align: 1,
          nowrap: true,
          type: this.$fieldTypes.FROMNOW,
        },
        {
          key: "warningText",
          name: "Warning",
          hideInTable: true,
          type: this.$fieldTypes.TEXT,
        }
      ],
      selection: [],
      opts: {
        statusEnquadramento: [
          {
            id: 0,
            nome:
              '<i class="v-icon notranslate mdi mdi-checkbox-blank-circle theme--dark" style="color: #E5CB5E; font-size: 16px;"></i> Pendente',
          },
          {
            id: 1,
            nome:
              '<i class="v-icon notranslate mdi mdi-checkbox-blank-circle theme--dark" style="color: #25935F; font-size: 16px;"></i> Aprovado',
          },
          {
            id: -1,
            nome:
              '<i class="v-icon notranslate mdi mdi-checkbox-blank-circle theme--dark" style="color: #EF4848; font-size: 16px;"></i> Reprovado',
          },
        ],
        trabalhos: [
          {
            id: 0,
            nome: `<span style="color: red;">Nenhum</span>`,
          },
          {
            id: BeneficiosEnum.LEI_DO_BEM,
            nome: BeneficiosLabels.LEI_DO_BEM,
          },
          {
            id: BeneficiosEnum.LEI_DE_INFORMATICA,
            nome: BeneficiosLabels.LEI_DE_INFORMATICA,
          },
          {
            id: BeneficiosEnum.LEI_DO_BEM_E_INFORMATICA,
            nome: BeneficiosLabels.LEI_DO_BEM_E_INFORMATICA,
          },
        ],
        statusOpts: [],
      },
      statusCols: [
        {
          key: "status",
          name: "Status",
          type: this.$fieldTypes.SELECT,
          rel: { to: "statusOpts", key: "id", name: "label"}
        }
      ],
      statusModalOpened: false,
      statusModalData: {},
      justificativaModalOpen: false,
      justificativaModalData: { justificativa: ''},
      justificativaSelectionModalOpen: false,
      justificativaSelectionModalData: { idProjetosJustificados: [], justificativa: '', isApproved: false},
      projetos: [],
      exportacaoAsyncDialog: false,
      userJustify: false,
      tituloProjetosJustificados: '',
      projetosJustificados: [],
      isProjetosJustificadosEmLote: false,
      tituloProjetoSelecionado: '',
      justificativaSubtitle: '',
      projetosSelecionadosParaJustificacao: []
    };
  },
  methods: {
    alterarSituacaoProjetos: function (aprovar = false) {
      const projetoIds = this.selection.map(({ id }) => id);
      const url =`${this.resourceUrl}/${aprovar ? 'aprovado' : 'reprovado'}?projetoIds=${projetoIds}`;
      this.apiResource(url).save({ idProjetosJustificados: [] }).then(() => {
        this.$refs.masterDetail?.doLoad();
        this.selection = [];
      });
    },
    salvarJustificativaEnquadramento: function () {
      this.resourceProjetos.save(this.justificativaModalData, this.justificativaModalData.id).then((response) => {
        if (response.error) {
          throw response;
        }
        const doLoad = this.$refs.masterDetail.doLoad;
        if (doLoad) {
          doLoad();
        }
        this.$notify({
          group: "geral",
          duration: 7000,
          type: "success",
          title: "Sucesso",
          text: "Sua justificativa foi salva.",
        });
      });
      this.justificativaModalOpen = false;
    },
    salvarJustificativaEnquadramentoEmLote: function () {
      const projetoIds = this.selection.map(({ id }) => id);
      const url =`${this.resourceUrl}/${this.justificativaSelectionModalData.isApproved ? 'aprovado' : 'reprovado'}?projetoIds=${projetoIds}`;
      const justificativaModal = this.justificativaSelectionModalData.justificativa;
      this.apiResource(url).save({ idProjetosJustificados: this.projetosSelecionadosParaJustificacao.map(({ id }) => id), justificativa: justificativaModal }).then((response) => {
        if (response.error) {
          throw response;
        }
        const doLoad = this.$refs.masterDetail.doLoad;
        if (doLoad) {
          doLoad();
        }
        this.selection = [];
        this.$notify({
          group: "geral",
          duration: 7000,
          type: "success",
          title: "Sucesso",
          text: "Sua justificativa foi salva.",
        });
      });
      this.justificativaSelectionModalOpen = false;
    },
    exportCsv: function (forAlt = false) {
      this.apiResource(`v1/clientes/${this.clientId}/exportacao`)
        .save({
          tipoRelatorio: forAlt ? "projetos-analise-edicao" : "projetos-analise", anoBase: this.$store.getters.anoBase
        })
        .then(() => {
          this.exportacaoAsyncDialog = true;
        });
    },
    verFila: function () {
      this.$router.push({
        path: "/clientes/dashboard/relatorios-exportados",
      });
    },
    validaProjetosComJustificativaEmLote: function (isApprove) {
      this.projetosSelecionadosParaJustificacao = this.selection;
      this.projetosJustificados = this.selection.filter((projeto) => (projeto.justificativa));
      this.justificativaSelectionModalData = { justificativa: '', isApproved: isApprove };
      if (this.projetosJustificados) {
        this.isProjetosJustificadosEmLote = true;
      } else {
        if (!this.selection.length) {
          return this.justificativaSelectionModalOpen = false;
        }
        this.openJustifySelectionModal();
      }
    },
    removeSelecaoProjetosJustificados: function () {
      this.projetosSelecionadosParaJustificacao = this.selection.filter((proj) => !this.projetosJustificados.includes(proj));
      this.isProjetosJustificadosEmLote = false;
      if (!this.projetosSelecionadosParaJustificacao.length) {
        return this.isProjetosJustificadosEmLote = true
      }
      return this.justificativaSelectionModalOpen = true;
    },
    openJustifySelectionModal: function() {
      this.isProjetosJustificadosEmLote = false;
      this.justificativaSelectionModalOpen = true;
    },
    alterarStatusProjetos() {
      this.statusModalOpened = true;
      this.apiResource(`/v1/projetos/${this.clientId}/status?tipo=analise`).get().then((result) => {
        this.opts.statusOpts = result.filter(({ label }) => !!label);
      });
    },
    async validaAlteracaoStatusProjeto(row) {
      if (row && !this.selection.includes(row)) {
        this.selection.push(row);
      }

      const [enquadrados, naoEnquadrados] = partition(this.selection, ({ enquadramento }) => enquadramento == EnquadramentoEnum.ENQUADRADO);
      const [reprovados, pendentes] = partition(naoEnquadrados, ({ enquadramento }) => enquadramento == EnquadramentoEnum.REPROVADO);
      this.selection = pendentes;

      if (!!enquadrados.length || !!reprovados.length) {
        await this.isProjectApprovedOrRepproved(!!enquadrados.length, !!pendentes.length, !!reprovados.length);
      }

      if (this.selection.some(({ agrupamento_titulo }) => !!agrupamento_titulo)) {
        this.errorMessage = "Alterar status de um projeto agrupado pode acarretar na remoção do projeto do agrupamento";
      } else {
        this.errorMessage = "";
      }

      if (this.selection.length) {
        this.alterarStatusProjetos();
      }
    },
    isProjectApprovedOrRepproved(approve = false, pending = false, reprove = false) {
      this.statusDialogWarning = {
        approve,
        pending,
        reprove,
      };

      return this.$refs.statusDialogWarning.asyncOpen();
    },
    saveStatus() {
      const projetoIds = this.selection.map(({ id }) => id);
      this.apiResource(`/v1/projetos/${this.clientId}/update-status`)
        .save({
          status: this.statusModalData.status,
          projetos: projetoIds
        }).then((result) => {
          if(result && !result.errors) {
            const doLoad = this.$refs.masterDetail.doLoad;

            if (doLoad) {
              doLoad();
            }

            this.selection = [];
            this.$notify({
              group: "geral",
              duration: 5000,
              type: "success",
              title: "Alterações salvas",
              text: `${result.message}`,
            });
          } else {
            this.$notify({
              group: "geral",
              duration: 5000,
              type: "error",
              title: "Erro",
              text: `${result.errors.status}`,
            })
          }
        })
      this.statusModalOpened = false;
    }
  },
};
</script>
<style lang="scss">
  #justificativa ::v-deep {
    .v-card {
      .v-card__title {
        align-items: center;
      }
    }
  }
</style>
